<template>
  <v-container fluid>
    <!-- <v-layout row wrap> -->
    <v-row justify="center" mb-4 mt-2>
      <v-col>
        <h1>Equipos Transbank</h1>
        <h3>Asocia equipos a un Order Id</h3>
      </v-col>
      <v-col justify="right" mb-4 mt-2>
        <v-col>
          <v-img src="../assets/transbank.png" width="200" height="80"> </v-img>
        </v-col>
      </v-col>
      <v-col justify="right">
        <v-btn
          @click="(dialogObjectIntegration = true), refresDiagInt()"
          color="success"
          class="white--text"
        >
          Generar Objeto Interación
        </v-btn>
      </v-col>
    </v-row>
    <!-- </v-layout> -->

    <!-- Fin Dialogo de Objeto Integracion -->

    <v-dialog
      persistent
      v-model="dialogObjectIntegration"
      lazy-validation
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          Ingrese el Order Id para enviar vía integración
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="OrderIdInt"
            :rules="OrderIdIntRules"
            label="Order Id"
            required
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="success"
            class="mr-4 mt-5"
            :disabled="toggleDisableButtonTbkInst()"
            @click="generarObjetoIntegracionSuccess()"
            :loading="sendingRequestInt"
          >
            Crear Objeto
          </v-btn>
          <v-btn
            color="primary"
            class="mr-4 mt-5"
            text
            @click="dialogObjectIntegration = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Fin Dialogo de Objeto Integracion -->
    <!--  Dialogos de mensajes -->
    <v-dialog
      persistent
      v-model="dialogMessage"
      lazy-validation
      max-width="400"
    >
      <v-card theme="dark">
        <v-card-title class="headline primary white--text py-1" primary-title>
          {{ messageTbkInstTitle }}
        </v-card-title>

        <v-card-text class="text-h6">
          <br />
          {{ messageTbkInst }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="red"
            class="mr-4 mt-5"
            text
            @click="(dialogMessage = false), (dialogObjectIntegration = true)"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- fin de dialo de memsajes -->

    <hr />
    <v-row justify="center">
      <v-col cols="6">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="OrderId"
            :rules="OrderIdRules"
            label="Order Id"
            required
          ></v-text-field>

          <v-text-field
            v-model="equipoTbk"
            :rules="equipoTbkRules"
            label="Equipo(s)"
            required
          ></v-text-field>

          <v-btn
            color="success"
            class="mr-4 mt-5"
            @click="agregarEquipoTbk"
            :disabled="toggleDisableButtonTbk()"
            :loading="sendingRequest"
          >
            Asociar equipo(s)
          </v-btn>

          <v-btn color="warning" class="mr-4 mt-5" @click="reset">
            Limpiar
          </v-btn>

          <div class="mt-5 mx-5">
            <v-alert
              :value="alerta"
              type="success"
              transition="scale-transition"
              outlined
            >
              Equipo(s) asociado(s) correctamente, se generó nueva etiqueta!.
            </v-alert>
            <v-alert
              :value="alertaNegativa"
              type="error"
              transition="scale-transition"
              outlined
            >
              {{ this.message }}
            </v-alert>
          </div>
        </v-form>
        <v-data-table
          :headers="headers"
          :items="arregloFulFillment"
          :loading="loading"
        >
          <template v-slot:items="props">
            <!-- <td :style="{ 'background-color': props.item.color }"> -->
            <td :style="{ 'background-color': '#ffffff' }">
              <strong>{{ props.item.orderID }}</strong>
            </td>
            <td>{{ props.item.items }}</td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import firebase from "firebase";
import Vue from "vue";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

export default {
  data: () => ({
    sendingRequest: false,
    sendingRequestInt: false,
    alerta: false,
    message: "",
    alertaNegativa: false,
    alertaTBKInst: true,
    messageTbkInst: "",
    messageTbkInstTitle: "",
    alertaPdf: false,
    valid: false,
    loaderPdf: false,
    dialogObjectIntegration: false,
    dialogMessage: false,
    loadingTable: false,
    loading: false,
    dialog: false,
    dialogoPdf: false,
    etiquetas: "",
    OrderId: "",
    OrderIdInt: "",
    cliente: "",
    clienteEtiqueta: "",
    tipoEtiqueta: "",
    headers: [
      { text: "Order Id", value: "order_id_task", align: "start" },
      { text: "Items", value: "items" },
    ],
    tipoRules: [(v) => !!v || "Tipo de entrega es requerida"],
    clientesRules: [(v) => !!v || "Cliente es requerido"],
    OrderIdRules: [
      (v) => !!v || "Order Id es requerido",
      (v) => (v && v.length >= 5) || "OrderId debe ser mayor de 5 caracteres",
    ],
    OrderIdIntRules: [
      (v) => !!v || "Order Id es requerido",
      (v) => (v && v.length >= 5) || "OrderId debe ser mayor de 5 caracteres",
    ],
    regionesRule: [
      (v) => !!v || "Campo requerido",
      (v) => (v && v.length >= 6) || "Debes seleccionar una región",
    ],
    arregloFulFillment: [],

    equipoTbk: "",
    equipoTbkRules: [(v) => !!v || "Se requiere código de equipo"],
    // select: 0,
    // items: [1, 2, 3, 4],
    nombreCampo: "added_kits",

    textoConfirmacion: "",
    color: "warning",
    statusLlamada: 0,
  }),

  methods: {
    refresDiagInt() {
      this.OrderIdInt = "";
    },
    toggleDisableButtonTbk() {
      if (this.OrderId.length < 5 || this.equipoTbk.length < 5) {
        return true;
      } else {
        return false;
      }
    },

    toggleDisableButtonTbkInst() {
      if (this.OrderIdInt.length < 5) {
        return true;
      } else {
        return false;
      }
    },
    reset() {
      this.$refs.form.reset();
      this.equipoTbk, (this.OrderId = "");
      this.alerta = false;
      this.alertaNegativa = false;
      this.OrderIdInt = "";
    },
    async agregarEquipoTbk() {
      this.sendingRequest = true;
      // console.log(this.datosUsuario());
      try {
        console.log("Equipo TBK: " + this.equipoTbk.replace(/[';']/gi, ","));
        let equiposTBK = this.equipoTbk.replace(/[';']/gi, ",").trim();
        let arrgloEquipos = equiposTBK.split(",");

        let orderId = this.OrderId;
        let idCustomer = "6345add7241b3007baf0aee6"; //TBK Instalaciones
        let idCustomerTBK = "60993ed58f2e2bb84cf920a0"; // TBK para validar los equipos
        let buscarSerie;
        let pase = true;
        let validaProceso = false;
        let dataArreglo = {
          order_id_task: orderId,
          items: arrgloEquipos,
        };

        this.alerta = false;
        this.alertaNegativa = false;

        console.log("Order Id: " + orderId);
        const respuesta = await axios.get(
          `https://backendservices.rayoapp.com/Task-Groups/tasks_group/filters/by?idOrder=${orderId}&idCustomer=${idCustomer}`,
          {
            headers: {
              "warehouse.rayoapp.com": "rayo",
            },
          }
        );
        //console.log(respuesta.data[0].tasks[0]._id);
        if (respuesta.data[0]) {
          await Promise.all(
            arrgloEquipos.map(async (serie) => {
              console.log(serie);
              buscarSerie = await axios.get(
                `https://backendservices.rayoapp.com/Task-Groups/tasks_group/filters/by?idOrder=${serie}&idCustomer=${idCustomerTBK}`,
                {
                  headers: {
                    "warehouse.rayoapp.com": "rayo",
                  },
                }
              );

              if (!buscarSerie.data[0]) {
                //console.log("tarea no encontrada!");
                this.message = `La serie ${serie} no se encuentra cargada en sistema favor validar con el equipo de TI`;
                this.alertaNegativa = true;
                pase = false;
              }
            })
          );

          if (pase == true) {
            for (const n in respuesta.data[0].tasks) {
              var idTarea = respuesta.data[0].tasks[n]._id;
              // var orderId = respuesta.data[0].tasks[n].order_id_task;
              var equipos = equiposTBK;
              var equipoTbksSeparados = equipos.split(",");
              var arregloequipoTbks = [];
              for (let i = 0; i < equipoTbksSeparados.length; i++) {
                arregloequipoTbks[i] = equipoTbksSeparados[i];
              }
              // arregloequipoTbks[] = equipoTbksSeparados;
              console.log(arregloequipoTbks);

              var data = {
                field: this.nombreCampo,
                data: arregloequipoTbks,
              };

              const respuestaIdTarea = await axios.put(
                `https://backendservices.rayoapp.com/Tasks/tasks/${idTarea}/add_metadata`,
                data,
                {
                  headers: {
                    "warehouse.rayoapp.com": "rayo",
                  },
                }
              );
              console.log("Status: " + respuestaIdTarea.status);
              this.statusLlamada = respuestaIdTarea.status;

              if (this.statusLlamada === 200) {
                validaProceso = true;
              }
            }
            let newLabel = { orderID: orderId, email: this.datosUsuario() };
            //console.log(newLabel);
            const respuestaEtiqueta = await axios.post(
              `https://southamerica-east1-rayo-api.cloudfunctions.net/funcionesRayo/funcionesRayo/GeneraEtiquetaTBKInst`,
              newLabel
            );
            if (validaProceso === true && respuestaEtiqueta.status === 200) {
              this.alerta = true;
              this.sendingRequest = false;
              this.arregloFulFillment.push(dataArreglo);
            }
          }
        } else {
          console.log("tarea no encontrada!");
          this.message = `La orden ${orderId} no se encuentra registrada en sistema favor validar con el equipo de TI !`;
          this.alertaNegativa = true;
        }
      } catch (error) {
        this.alertaNegativa = false;
        console.log(error);
        this.alertaNegativa = true;
      }
      this.sendingRequest = false;
    },

    async generarObjetoIntegracionSuccess() {
      this.sendingRequestInt = true;
      let order_id = this.OrderIdInt.trim();
      let x, data;
      let idcustomer = "6345add7241b3007baf0aee6";
      let consulta = await axios.get(
        `https://backendservices.rayoapp.com/Task-Groups/tasks_group/filters/by?idOrder=${order_id}&idCustomer=${idcustomer}`,
        {
          headers: {
            "warehouse.rayoapp.com": "rayo",
          },
        }
      );

      // console.log("lo que recibo consulta " + JSON.stringify(consulta.data));

      if (consulta.data[0]) {
        for (x in consulta.data[0].tasks) {
          if (
            consulta.data[0].tasks[x].type_task == 2 &&
            consulta.data[0].tasks[x].idstatus_task ==
              "5f05f4077f5164494c74b96b"
          ) {
            data = consulta.data[0].tasks[x];
          }
        }

        if (data) {
          //validamos si paso el proceso de fulfillment
          // if (data.metadata_task.added_kits)
          let dataTaskhook = {};
          dataTaskhook.fullDocument = data;
          if (data.metadata_task.added_kits) {
            console.log("se envia la data a procesar");
            await axios.post(
              "https://transbanksoap-2jox3wghaa-uc.a.run.app/CrearTaskHook",
              dataTaskhook
            );
            this.messageTbkInstTitle = `Elemento creado correctamente"`;
            this.messageTbkInst = `¡Se ha creado correctamente el objeto para ser enviado!`;
            this.dialogObjectIntegration = false;
            this.dialogMessage = true;
          } else {
            this.messageTbkInstTitle = `Revisar Orden`;
            this.messageTbkInst = `¡La Orden no tiene series asociadas, favor validar proceso de fulFillment!`;
            this.dialogObjectIntegration = false;
            this.dialogMessage = true;
          }
        } else {
          this.messageTbkInstTitle = `Revisar Orden`;
          this.messageTbkInst = `¡El order Id no se encuentra exitoso!`;
          this.dialogObjectIntegration = false;

          this.dialogMessage = true;
        }
      } else {
        this.messageTbkInstTitle = `Mensaje`;
        this.messageTbkInst = `¡Orden no encontrada!`;
        this.dialogObjectIntegration = false;
        this.dialogMessage = true;
      }
      this.sendingRequestInt = false;
    },

    datosUsuario() {
      return firebase.auth().currentUser.email;
    },
  },
  computed: {
    deshabilitar() {
      return (
        this.OrderId === "" || this.equipoTbk === "" || this.OrderIdInt === ""
      );
    },
    deshabilitarBotonPdf() {
      if (this.clienteEtiqueta == "5fb7baf4c8c6410667c3f1bb") {
        return this.clienteEtiqueta == "" || this.tipoEtiqueta == "";
      } else {
        return this.clienteEtiqueta == "";
      }
    },
  },
};
</script>

<style scoped>
a.etiquetas {
  text-decoration: none;
  color: white;
}
/* .pad {
  pa
} */
</style>
